import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { Layout, Stories, Pagination } from "../../components"

const StoriesPage = ({ data, pageContext }) => {
  const {
    allAirtable: { nodes: stories },
  } = data

  // console.log("poems.js data", data)
  // console.log("poems.js pagecontext", pageContext)

  return (
    <Wrapper>
      <Layout>
        <Stories title="Novellák" stories={stories} page />
        <Pagination pageContext={pageContext} />
      </Layout>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  min-height: 100vh;
  ${"" /* background from Poems.js  */}
  background: var(--clr-light-3);
  padding-top: 5rem;
  nav {
    background-color: rgba(0, 0, 0, 0.85);
  }
`

export const query = graphql`
  query StoriesQuery($skip: Int, $limit: Int) {
    allAirtable(
      filter: { table: { eq: "Stories" } }
      limit: $limit
      skip: $skip
      sort: { fields: data___stories_row, order: DESC }
    ) {
      nodes {
        id
        recordId
        data {
          stories_title
          stories_excerpt
          stories_author
          stories_row
          stories_text {
            childMarkdownRemark {
              html
            }
          }
          stories_image {
            localFiles {
              childImageSharp {
                fluid {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StoriesPage
